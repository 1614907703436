@import "../../../theme/index";

.xpedition-item {
  &__header {
    height: 60px;
    background-color: $grey;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    @include respond-to(mobile) {
      font-size: 20px;
    }
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 30px;
    text-transform: capitalize;
  }

  &__body {
    @include respond-to(tablets) {
      display: flex;

    }
    border-bottom: 1px solid $darkGrey;
    padding-bottom: 20px;
  }

  &__image {
    width: 100%;

    &-wrapper {
      max-width: 20%;
    }
  }

  &__description {
    padding: 20px;
    flex: 1 1 auto;
    @include respond-to(tablets) {
      padding: 0 20px;
    }
  }

  &__button {
    //height: 100%;
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;

    &:hover {
      transition: color 0.3s;
      color: $silver;
    }

    &-percent {
      font-size: 48px;
      font-weight: bold;
      display: block;
      margin-top: 50px;
    }

    &-label {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 20px;
    }

    &-wrapper {
      min-width: 25%;
    }

    &--remove {
      margin-top: 10px;
    }
  }
}